








import Vue from 'vue'

import BasePagePreloader from '@/components/BasePagePreloader.vue'

export default Vue.extend({
  name: 'Profile',
  components: {
    BasePagePreloader
  }
})
